import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { ChainIds, SupportedChains } from "./chainIds";
import { getKeyByValue } from "./util";
import { JsonRpcCall } from '../types/types'

export const ExpectedChainId = process.env.REACT_APP_CHAIN_ID;
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

const web3 = createAlchemyWeb3(alchemyKey);
const contractABI = require('../assets/contracts/super-ice-cream-party-abi.json')

// WALLET METHODS
/**
 * 
 * @returns Prompts the user to connect their wallet to the website using metamask
 */
export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addresses = await window.ethereum.request({ method: "eth_requestAccounts" });
      return addresses.length ? addresses[0] : '';
    }
    catch (err) {
      console.error(err);
    }
  }
  return '';
}

export const switchChain = async () => {
  if (!window.ethereum) return;
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: ExpectedChainId }],
    });
  } catch (switchError) {
    console.error(switchError);
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
    }
    // handle other "switch" errors
  }
}

/**
 * 
 * @returns Gets the current wallet connected with metamask
 */
export const getConnectedAccount = async () => {
  if (window.ethereum) {
    try {
      const addresses = await window.ethereum.request({ method: "eth_accounts" });
      return addresses.length ? addresses[0] : '';
    }
    catch (err) {
      console.err(err);
    }
  }
  return '';
}

// CHAIN METHODS
export const getEthChainId = async () => {
  const hexChainId = await window.ethereum.request({ method: 'eth_chainId' });
  return hexChainId;
}

export const getChain = (hexChainId) => {
  return SupportedChains.find(x => x.hex === hexChainId);
}

export const getChainName = (hexChainId) => {
  return SupportedChains.find(x => x.hex === hexChainId)?.name;
}

export const validateChain = (chainId) => {
  if (chainId === ExpectedChainId) return { result: true, error: '' };
  return { result: false, error: `Please select network: ${getKeyByValue(ChainIds, ExpectedChainId).toUpperCase()}` };
};

export const mintNft = async (amount, mintPrice, method) => {
  // load smart contract
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  const valueToSend = amount * mintPrice;
  const wei = web3.utils.toWei(String(valueToSend), 'ether');
  const estimatedGas = String(300000);

  const transactionParameters = {
    from: window.ethereum.selectedAddress, // must match user's active address.
    to: contractAddress,
    gas: web3.utils.toHex(estimatedGas),
    value: web3.utils.toHex(wei),
    data: window.contract.methods[method](amount).encodeABI() //make call to NFT smart contract 
  }

  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    });
    return {
      success: true,
      txHash: txHash
    }
  } catch (error) {
    return { success: false };
  }
};

/**
 * Get contract information needed to render the page
 */
export const getContractInformation = async () => {
  const contract = await new web3.eth.Contract(contractABI, contractAddress);

  // batch call for all needed contract fields
  const batchCall = [];
  // const maxTokensCall = new JsonRpcCall({ id: 1, params: [{ data: contract.methods.MAX_TOKENS().encodeABI(), to: contractAddress }, 'latest'] });
  const totalSupplyCall = new JsonRpcCall({ id: 1, params: [{ data: contract.methods.totalSupply().encodeABI(), to: contractAddress }, 'latest'] });
  const saleStatusCall = new JsonRpcCall({ id: 2, params: [{ data: contract.methods.saleStatus().encodeABI(), to: contractAddress }, 'latest'] });
  const publicMintCountsCall = new JsonRpcCall({ id: 3, params: [{ data: contract.methods.publicMintCounts(window.ethereum.selectedAddress).encodeABI(), to: contractAddress }, 'latest'] });

  // batchCall.push(maxTokensCall);
  batchCall.push(totalSupplyCall);
  batchCall.push(saleStatusCall);
  batchCall.push(publicMintCountsCall);

  const response = await fetch(`${alchemyKey}`, {
    method: 'POST',
    Headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(batchCall)
  });
  const data = await response.json();

  const maxSupply = 10000;//web3.utils.hexToNumber(data[0].result);
  const currentSupply = web3.utils.hexToNumber(data[0].result);
  const saleStatus = web3.utils.hexToNumber(data[1].result);
  const publicMintCounts = web3.utils.hexToNumber(data[2].result);

  const remainingSupply = parseInt(maxSupply) - parseInt(currentSupply);

  return {
    currentSupply: currentSupply,
    maxSupply: maxSupply,
    remainingSupply: remainingSupply,
    saleStatus: saleStatus,
    publicMintCounts: publicMintCounts,
    publicPrice: "0", //web3.utils.fromWei(publicPrice, 'ether'),
    unlimitedPrice: "0.001" //web3.utils.fromWei(unlimitedPrice, 'ether')
  };
}
