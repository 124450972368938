class JsonRpcCall {
  constructor(options) {

    // Default values
    const defaults = {
      jsonrpc: '2.0',
      id: 0,
      method: 'eth_call',
      params: []
    };

    let opts = Object.assign({}, defaults, options);

    // assign options to instance data (using only property names contained
    //  in defaults object to avoid copying properties we don't want)
    Object.keys(defaults).forEach(prop => {
      this[prop] = opts[prop];
    });
  }
}

export { JsonRpcCall };