import React, { useState } from 'react';

// https://medium.com/actived/how-to-share-state-between-multiple-components-without-passing-them-in-props-or-using-a-library-224480a94cf1
export const DEFAULT_CONTRACT = {
  publicPrice: 0,
  unlimitedPrice: 0,
  remainingSupply: 0,
  maxSupply: 0,
  saleStatus: null
}

export const ETH_ERROR_CODES = {
  'METAMASK_NOT_FOUND': 'Metamask not found, please install',
  'WALLET_NOT_CONNECTED': 'Wallet not connected',
  'CHAIN_INVALID': 'Please connect to the right chain'
}

const EthContractContext = React.createContext({
  ethContract: null,
  ethAccount: null,
  formattedEthAccount: null,
  currentChain: null,
  setEthContract: (value) => console.error('not yet implemented'),
  setEthAccount: (value) => console.error(),
  setFormattedEthAccount: (value) => console.error(),
  setCurrentChain: (value) => console.error('not yet implemented')
});

export const EthContractContextProvider = ({ children }) => {

  const [ethContract, setEthContract] = useState(DEFAULT_CONTRACT);
  const [ethAccount, setEthAccount] = useState(null);
  const [formattedEthAccount, setFormattedEthAccount] = useState('');
  const [currentChain, setCurrentChain] = useState(null);
  const [ethErrorCode, setEthErrorCode] = useState(null);

  return (
    <EthContractContext.Provider value={{ 
      ethContract, setEthContract,
      ethAccount, setEthAccount,
      currentChain, setCurrentChain, 
      ethErrorCode, setEthErrorCode,
      formattedEthAccount, setFormattedEthAccount }}>
      {children}
    </EthContractContext.Provider>
  );
};

export const useEthContractContext = () => React.useContext(EthContractContext);