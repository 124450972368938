
import React from 'react';

import yellowDemon from '../assets/images/yellowdemon.png';
import drunkenDemon from '../assets/images/drunkendemon.png';
import noFaceDemon from '../assets/images/nofacedemon.png';
import wanderingDemon from '../assets/images/wanderingdemon.png';
import twitterIcon from '../assets/images/icon-twitter-lg.png'

function TeamSection() {
  return (
    <footer>
      <div className="col-12 about-the-team" id="team">
        <div className="team-title">WHO'S THROWING<br/>THE PARTY?</div>
        <div className="team-subtitle">It's these Jobbers!</div>
        <div className="col-12 footer_team">
          <div className="teamsection">
            <div className="col-3 teamColumn">
              <img src={yellowDemon} alt="" width="300" height="300" className="cards"/>
              <h3 className="teammembers yellow">THE YELLOW<br/>DEMON</h3>
              <p className="teammembers yellow">PROJECT MANAGER</p>
              <a href="https://twitter.com/theyellxwdemon"><img src={twitterIcon} alt="twitter" width="55" height="44"/></a>
            </div>
            <div className="col-3 teamColumn">
              <img src={drunkenDemon} alt="" width="300" height="300" className="cards"/>
              <h3 className="teammembers green">THE DRUNKEN<br/>SHIBA</h3>
              <p className="teammembers green">ARTIST/DESIGNER</p>
              <a href="https://twitter.com/TheDrunkenShiba"><img src={twitterIcon} alt="twitter" width="55" height="44"/></a>
            </div>
            <div className="col-3 teamColumn">
              <img src={noFaceDemon} alt="" width="300" height="300" className="cards"/>
              <h3 className="teammembers pink">TRES<br/>GATOS</h3>
              <p className="teammembers pink">ARTIST/DEVELOPER</p>
              <a href="https://twitter.com/tresgatos_"><img src={twitterIcon} alt="twitter" width="55" height="44"/></a>
            </div>
            <div className="col-3 teamColumn">
              <img src={wanderingDemon} alt="" width="300" height="300" className="cards"/>
              <h3 className="teammembers orange">THE WANDERING<br/>NFT</h3>
              <p className="teammembers orange">DEVELOPER</p>
              <a href="https://twitter.com/wanderingnft"><img src={twitterIcon} alt="twitter" width="55" height="44"/></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default TeamSection;