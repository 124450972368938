
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { AppBar } from "@mui/material";
import Box from '@mui/material/Box';
import React from "react";

export default function MainNav() {

  return (
    <AppBar position="sticky" sx={{ background: '#68eee1', boxShadow: 'none', minHeight: '80px', paddingLeft: '20px', paddingRight: '20px' }}>
      <Container maxWidth="none">
        <Toolbar disableGutters>
          <Box sx={{ flex: 1, verticalAlign:'middle', marginTop: '15px' }}>
            <a href="/"><div className="logo"></div></a>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'right', minHeight: '28px', verticalAlign: 'middle', marginTop: '15px' }}>
            {/* <DebugMode /> */}
            <a href="https://twitter.com/icecreamsnft"><div className="socialmedia-twitter" /></a>
            <a href="https://discord.gg/eCJB5YUyKh"><div className="socialmedia-discord" /></a>
            <a href="https://opensea.io/collection/super-ice-cream-party"><div className="socialmedia-os" /></a>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
