import React from 'react';

function CopyrightSection() {
  return (
    <div className="copyright col-12">
      <div className="col-12 copyright-text">
        &copy;2022 - <strong>Kayfabe Labs</strong>
      </div>
    </div>
  )
}

export default CopyrightSection;