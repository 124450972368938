
import React from 'react';

import softserve from '../assets/images/icon-softserve.png';
import icecream from '../assets/images/icon-icecream.png';

function RoadmapSection() {
  return (
    <div className="col-12 roadmap" id="roadmap">
      <div className="roadmap-header">
        NEIGHBORHOOD<br />ROADMAP
      </div>

      <div className="the-launch">
        <div className="launch-image">
          <img src={softserve} alt="" />
        </div>
        <div className="launch-text">
          <div className="launch-text-header">The Launch</div>
          <div className="launch-text-desc">The Super Ice Cream Party starts soon. 10,000 ice creams are on the house while supplies last (20 ice creams max per wallet). Rare and legendary ice creams are sprinkled throughout the collection. Holders of Kayfabe Superstars NFTs (Eth and Polygon) will be able to join the pre-party for an additional 20 mints. </div>
        </div>
      </div>

      <div className="the-utility">
        <div className="utility-image">
          <img src={icecream} alt="" />
        </div>
        <div className="launch-text">
          <div className="launch-text-header">The Utility</div>
          <div className="utility-text-desc">Ice creams will act as a mint pass for future Kayfabe Labs projects and drops.</div>
        </div>
      </div>
    </div>
  )
}

export default RoadmapSection;