import './App.css';
import RoadmapSection from './components/RoadmapSection';
import TeamSection from './components/TeamSection';
import CopyrightSection from './components/CopyrightSection';
import MainSetSection from './components/MainSetSection';
import AboutSection from './components/AboutSection';
import IceCreamTruckSection from './components/IceCreamTruckSection';
import MainNav from './components/MainNav';
import { EthContractContextProvider, useEthContractContext, ETH_ERROR_CODES } from './store/ethContractContext';
import React, { useEffect } from 'react';
import { ExpectedChainId, getContractInformation, getEthChainId, getConnectedAccount, getChain } from './utils/walletConnectUtil';

function App() {
  return (
    <div className="container">
      <EthContractContextProvider>
        <ContractUpdater />
        <MainNav />
        <IceCreamTruckSection />
        <AboutSection />
        <MainSetSection />
        <RoadmapSection />
        <TeamSection />
        <CopyrightSection />
      </EthContractContextProvider>
    </div>
  );
}

function ContractUpdater() {

  const { setEthContract, setEthAccount, setEthErrorCode, setCurrentChain, setFormattedEthAccount } = useEthContractContext();

  function onAccountsChanged(accounts) {
    setEthAccount(accounts.length ? accounts[0] : '');
    setFormattedEthAccount(accounts.length ? String(accounts[0]).substring(0, 6) + "..." + String(accounts[0]).substring(38) : '');
    stateCheck();
  }

  async function stateCheck() {

    setEthErrorCode('');

    if (!window.ethereum) {
      console.error(ETH_ERROR_CODES.METAMASK_NOT_FOUND);
      setEthErrorCode(ETH_ERROR_CODES.METAMASK_NOT_FOUND);
      return;
    }

    const address = await getConnectedAccount();
    if (!address.length) {
      console.error(ETH_ERROR_CODES.WALLET_NOT_CONNECTED);
      setEthErrorCode(ETH_ERROR_CODES.WALLET_NOT_CONNECTED);
      return;
    }

    // make sure you are on the correct network, if not, show user message
    const chainId = await getEthChainId();
    if (chainId !== ExpectedChainId) {
      console.error(ETH_ERROR_CODES.CHAIN_INVALID);
      setEthErrorCode(ETH_ERROR_CODES.CHAIN_INVALID);
      return;
    }

    // get contract information
    const info = await getContractInformation();
    setEthContract(info);
  }

  useEffect(() => {
    async function init() {
      setCurrentChain(getChain(ExpectedChainId));
      const connectedAccount = await getConnectedAccount();
      onAccountsChanged(connectedAccount.length ? [connectedAccount] : []); // triggers state check
      // todo: listen to transfer events
      // detect Network account change
      if (window.ethereum) {
        window.ethereum.on('chainChanged', (_chainId) => window.location.reload());
        window.ethereum.on('accountsChanged', onAccountsChanged);
      }
    }
    init();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', onAccountsChanged);
      }
    }
  }, []);

  return (
    <React.Fragment />
  )
}

export default App;
