import React from "react";

import icecreamrowimages from '../assets/images/sample-row.gif';
import dotsimages from '../assets/images/dots.png';
import icecreampurist from '../assets/images/icecream-purist01.png';
import icecreamrandom from '../assets/images/ice-cream-random.png';

function MainSetSection() {
  return (
    <div className="col-12 mainsections">
      <div className="col-12 stripe-divider" />

      <div className="col-6 flavor-section">
        <div className="flavor-text">
          LOTS OF FLAVORS!<br />LOTS OF TOPPINGS
        </div>
        <div className="flavor-text-desc">
          Every ice cream is unique and randomly generated. There will be various flavors, cones/containers, toppings, add ons, backgrounds, and more!
        </div>
      </div>

      <div className="ice-cream-row-container">
        <div className="ice-cream-holder">
          <img className="icecream-row-images" src={icecreamrowimages} alt="" />
        </div>
      </div>

      <div className="col-12 dot-rule">
        <img src={dotsimages} alt="" /><img src={dotsimages} alt="" /><img src={dotsimages} alt="" />
      </div>

      <div className="col-12 horizontal-section-1">
        <div className="col-6 section-1-image">
          <img src={icecreampurist} width="327" height="327" alt="" />
        </div>
        <div className="col-6 vertical_align_text">
          <div className="section-1-left">
            <div className="section-1-header">NOT A FAN OF THE<br />EXTRA STUFF?</div>
            <div className="section-1-desc">For those who want the purest form of these frozen treats, look out for the Purist Collection. No add-ons, just straight up ice cream and soft serve.</div>
          </div>
        </div>
      </div>

      <div className="col-12 dot-rule">
        <img src={dotsimages} alt="" /><img src={dotsimages} alt="" /><img src={dotsimages} alt="" />
      </div>

      <div className="col-12 horizontal-section-2">
        <div className="col-6 vertical_align_text">
          <div className="section-2-left">
            <div className="section-2-header">AND OF  COURSE,<br />WE GOT SOME 1/1s</div>
            <div className="section-2-desc">Are these even edible?</div>
          </div>
        </div>
        <div className="col-6 section-2-image">
          <img src={icecreamrandom} width="327" height="327" alt="" />
        </div>
      </div>

      <div className="col-12 stripe-divider">
      </div>
    </div>
  )
}

export default MainSetSection;