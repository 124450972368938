import React, { useEffect, useState } from "react";

import scrollarrow from '../assets/images/scrollarrow.png';
import IcDialog, { IcDialogStatus } from "../common/IcDialog";
import { useEthContractContext, ETH_ERROR_CODES } from "../store/ethContractContext";
import { connectWallet, mintNft, switchChain } from "../utils/walletConnectUtil";

function IceCreamTruckSection() {

  const [mintNumber, setMintNumber] = useState(1);
  const [mintTooltip, setMintTooltip] = useState('Mint Now!');
  const [canMint, setCanMint] = useState(null);
  const [title, setTitle] = useState('');
  const [method, setMethod] = useState('');
  const [price, setPrice] = useState(0);
  const [open, setOpen] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({});
  const { ethContract, ethErrorCode, currentChain, formattedEthAccount, ethAccount } = useEthContractContext();

  useEffect(() => {
    _initializePage();
  }, [ethContract]);

  const mintMessages = {
    'mintStart': {
      status: IcDialogStatus.Info,
      title: 'Minting!',
      text: 'Transaction in progress... {TRANSACTION_HASH}',
    },
    'mintSuccess': {
      status: IcDialogStatus.Success,
      title: 'Success!',
      text: 'Congrats! You have successfully minted {MINT_NUMBER}!'
    },
    'mintError': {
      status: IcDialogStatus.Error,
      title: 'Error!',
      text: 'Mint failed. Please try again.',
    }
  };

  async function mint() {
    const response = await mintNft(mintNumber, price, method);
    if (response.success) {
      const mintMessage = mintMessages['mintStart'];
      mintMessage.text = mintMessage.text.replace("{TRANSACTION_HASH}", response.txHash);
      setDialogInfo(mintMessage);
      handleOpen();
    } else {
      setDialogInfo(mintMessages['mintError']);
      handleOpen();
    }
  }

  const _initializePage =() => {
    const saleStatus = ethContract.saleStatus;

    // NONE
    if (saleStatus === 0) {
    }
    // PRIVATE
    else if (saleStatus === 1) {
      setTitle('(PRESALE)');
      setMethod('privateMint');

      var presalelist = ['0xDE052D9D5E393784B665A7FfD3351Ee89747Ad22', '0xC12DDc752a9987336b1242fa098dfe5fb458eeCd', '0xD01c8B23b569D34AeC1c99DdE6171C2F44b97233', '0x88a913Fb7217881833C55DC3C47f18ddEd8496f4', '0x1411A4EB560dbE2BdabF38C8b65d4b19222455Ae', '0xF06d9eFbFef765F2D62C32Bc71438d929Bb53d52', '0x40CC4209c47C60285d17C4C4c40E02e3410B7c85', '0xdeF20db25523329ae9c9074bb9755cB97f560922', '0x35274d4749278773CB26061A8935dF49C921015c', '0x374a40e0B68F66004333CD2727692232809F6074', '0x0D3806f6528D38b9835063F659687D8b64912E63', '0xd2b838eB2e9Ca5D49826e9B7c4E71D135efa6Ff3']
      for (let i = 0; i < presalelist.length; i++) { presalelist[i] = presalelist[i].toUpperCase(); }

      setCanMint((ethAccount !== null) ? presalelist.includes(ethAccount.toUpperCase()) : false);
      setMintTooltip(!canMint ? 'Address is not in Presale List' : 'Mint Now!');
    }
    // PUBLIC
    else if (saleStatus === 2) {
      setMethod('publicMint');
      setPrice(ethContract.publicPrice);
      //setCanMint(true);
      checkMintNumber(1);
    }
    // UNLIMITED
    else if (saleStatus === 3) {
      setTitle('(AYCE)');
      setMethod('unlimitedMint');
      setPrice(ethContract.unlimitedPrice);
      setCanMint(true); 
      checkMintNumber(1);
    }
  }

  function onMintNumberChange(event) {
    const mintNumber = event.target.value;
    setMintNumber(mintNumber);
    checkMintNumber(parseInt(mintNumber));
  }

  function checkMintNumber(mintNumber) {
    let minttooltip = '';
    let canmint = canMint;
    if (ethContract.saleStatus === 2 && (ethContract.publicMintCounts >= 20 || 20 - ethContract.publicMintCounts < mintNumber)) { canmint = false; minttooltip = 'Minting will exceed your 20 per wallet limit'; }
    else if (mintNumber > ethContract.remainingSupply) { canmint = false; minttooltip = 'Mint number is greater than remaining supply'; }
    else if (mintNumber > 20) { canmint = false; minttooltip = "Cannot mint more than 20 per wallet"; }
    else { canmint = true; minttooltip = 'Mint Now!'; }
    
    // update state if changed
    if(canmint !== canMint) setCanMint(canmint);
    if(minttooltip !== mintTooltip) setMintTooltip(minttooltip);
  }

  async function onConnect() {
    await connectWallet();
  }

  async function onSwitchChain() {
    await switchChain();
  }

  /* modal logic */
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="icecream-truck-section col-12 col-s-12" id="icecream-truck-section">
      <div className="icecream-truck col-12 col-s-12">
        <div className="icecream-truck-image col-12 col-s-12">

          {ethErrorCode === ETH_ERROR_CODES.METAMASK_NOT_FOUND &&
            <div className="minting-text">
              <div className="minting-text-title">Metamask Not Found!</div>
              <div className="minting-connect-text">Please install <a href="https://metamask.io/download/">metamask</a>.</div>
            </div>
          }

          {ethErrorCode === ETH_ERROR_CODES.WALLET_NOT_CONNECTED &&
            <div className="minting-text">
              {/* <div className="minting-text-title">NOW MINTING!</div> */}
              <div className="minting-connect-text">Connect to the Ethereum network to mint tokens.</div>
              <button className="connect-button" id="walletButton" onClick={onConnect}>CONNECT!</button>
            </div>
          }

          {ethErrorCode === ETH_ERROR_CODES.CHAIN_INVALID &&
            <div className="minting-text">
              <div className="minting-text-title">Wrong Network!</div>
              <div className="minting-connect-text">Switch to the {currentChain?.name} to mint tokens.</div>
              <button className="connect-button" id="walletButton" onClick={onSwitchChain}>Switch Network</button>
            </div>
          }

          {!ethErrorCode && (!ethContract || ethContract.saleStatus === 0) &&
            <div className="coming-soon-section col-12 col-s-12" />
          }

          {!ethErrorCode && ethContract && ethContract.saleStatus > 0 && ethContract.remainingSupply === 0 &&
            /*show sold out message*/
            <div className="mint-section col-12 col-s-12">
              <div className="minting-text" style={{ marginTop: '0px' }}>
                <div className="minting-text-title">SOLD OUT!</div>
                <div className="minting-text-price">View the collection on <a href="https://opensea.io/KayfabeLabs?tab=created">OpenSea</a></div>
              </div>
            </div>
          }

          {!ethErrorCode && ethContract && ethContract.saleStatus > 0 && ethContract.remainingSupply > 0 &&
            <div className="mint-section col-12 col-s-12">
              <div className="minting-text">
                <div className="minting-text-title">NOW MINTING {title}</div>
                <div className="minting-text-num">{ethContract.remainingSupply}/{ethContract.maxSupply} remaining</div>
                <div className="minting-text-price">{price} Eth each (MAX 20 PER WALLET)</div>
              </div>
              <div className="minting-inputs">
                <div className="mint-num">
                  <input type="number" className="numInput" id="quantity" min={1} max={20}
                    value={mintNumber}
                    onChange={onMintNumberChange} />
                </div>
                <div className="mint-btn-section">
                  <button className="mint-button" id="mint-button" onClick={mint} disabled={mintNumber > 20 || mintNumber > ethContract.remainingSupply || !canMint}>
                    <span>MINT!</span>
                  </button>
                  <span className="tooltiptext" >{mintTooltip}</span>
                </div>
              </div>
              <div className="minting-text-wallet">
                CONNECTED: {currentChain?.name} {formattedEthAccount}
              </div>
            </div>
          }
        </div>
      </div>

      <IcDialog open={open} onClose={handleClose} dialogInfo={dialogInfo} />

      <div className="scroll-down-section col-12">
        <div className="scroll-down-text">SCROLL DOWN</div>
        <div className="scroll-down-arrow">
          <img src={scrollarrow} alt="" />
        </div>
      </div>
    </div>
  )
}

export default IceCreamTruckSection;