import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const IcDialogStatus = {
  Error: 'error',
  Info: 'info',
  Success: 'success'
}

export default function IcDialog(props) {

  const { onClose, open, dialogInfo } = props;
  const handleClose = () => onClose();


  useEffect(() => {
  }, [dialogInfo]);

  function getCssName() {
    if(!dialogInfo) return '';
    return `ic-dialog-${dialogInfo.status}`;
  }

  return (
    <Dialog fullWidth maxWidth="lg"
      onClose={handleClose} 
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`ic-dialog ${getCssName()}`}
      PaperProps={{
        style: {
          backgroundColor: '#fed97e',
          boxShadow: 'none',
          border: '#ec9c02 4px solid',
          filter: 'drop-shadow(3px 3px 0px #68eee1)',
        },
      }}
    >

      <DialogTitle id="ic-dialog-title">
        <div className="ic-dialog-title-style">
          {dialogInfo?.title}
        </div>
      </DialogTitle>

      <DialogContent>
      <div className="ic-dialog-text-style">
          {dialogInfo?.text &&
            <DialogContentText id="ic-dialog-description">
              {dialogInfo.text}
            </DialogContentText>
          }
          {dialogInfo?.htmlContent}
        </div>
      </DialogContent>

      <DialogActions style={{justifyContent: 'center'}}>
        <div className="ic-dialog-button">
          {!dialogInfo?.dialogActions &&
            <Button variant="outlined" onClick={handleClose} sx={{':hover': {backgroundColor:'#68eee1', border:'#3a988f 2px solid', color:'#384241',}, backgroundColor:'#68eee1', border:'#3a988f 2px solid', color:'#384241', fontFamily:'Filmotype', fontSize:'30pt', padding: '11px 30px 0px 30px'}}>Close</Button>
          }

          {dialogInfo?.dialogActions}
        </div>
      </DialogActions>
    </Dialog>
  )
}
