import React from "react";

function AboutSection() {
  return (
    <section className="about col-12" id="about">
      <div className="about-column-left">WHAT IS THIS<br />SUPER ICE<br />CREAM PARTY?</div>
      <div className="about-column-right">To us, ice cream is more than just a dessert. Ice cream is a simple, guilty pleasure. Ice cream is a cool treat that brings a smile to everyone's face (unless you are lactose intolerant). It is a 	fleeting treat that must be consumed before it melts away.<br /><br />
        What better way to introduce Kayfabe Labs to friends in the community than to throw a neighborhood ice cream party? Please join the Super Ice Cream Party and enjoy the exceptional flavors. Welcome to the coolest place around.
      </div>
    </section>
  )
}

export default AboutSection;